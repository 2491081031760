<template>
  <div class="Main">
    <Home
      v-if="bShowHome"
      v-on:showScanner="showScanner"
      v-on:toggleThemode="toggleThemode"
      v-bind:Scans="allScans"
      v-bind:total="total"
      v-bind:returnMessage="returnMessage"
      v-bind:classMessage="classMessage"
      v-bind:classMode="classMode"
      v-bind:modeMessage="modeMessage"
    ></Home>
    <BarcodeScanner
      v-if="bShowScanner"
      v-on:appendMessage="appendMessage"
      v-on:hideScanner="hideScanner"
      v-on:updateTotal="updateTotal"
    ></BarcodeScanner>
  </div>
</template>

<script>
import DBR from "../dbr";
import BarcodeScanner from "./BarcodeScanner";
import Home from "./Home";
export default {
  name: "Main",
  props: {},
  data() {
    return {
      resultValue: "",
      allScans: [],
      total: 0,
      libLoaded: false,
      bShowScanner: false,
      bShowHome: true,
      returnMessage: false,
      classMessage: false,
      classMode: "green_back",
      outScanMode: false,
      api_url: "https://apidev.urevent.fr/api/Scanapi/scan_dejcfp",
      modeMessage: "ENTREE",
      soundurl:
        "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3",
      badsoundurl: "https://soundbible.com/mp3/sms-alert-5-daniel_simon.mp3",
    };
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      //DBR.BarcodeReader._bUseFullFeature = true;
      await DBR.BarcodeScanner.loadWasm();
      this.libLoaded = true;
    } catch (ex) {
      alert(ex.message);
      throw ex;
    }

    fetch("https://apidev.urevent.fr/api/Scanapi/allincountnow_dej/24")
      .then((response) => response.json())
      .then((json) => {
        this.total = json;
      });
  },
  updated() {},

  components: {
    BarcodeScanner,
    Home,
  },

  methods: {
    playSound() {
      var audio = new Audio(this.soundurl);
      audio.play();
    },
    playbadSound() {
      var audio = new Audio(this.badsoundurl);
      audio.play();
    },
    appendMessage(message) {
      this.hideScanner();
      this.returnMessage = "Checking.......";
      this.classMessage = "warning";
      switch (message.type) {
        case "result":
          this.$auth
            .fetch(this.api_url, {
              method: "POST",
              body: {
                idregistered: message.text,
                scanning_location: "salle 3.2",
                scanning_session:
                  "S27 - Addictions aux jeux vidéo et à Internet : actualités",
              },
            })
            .then((response) => {
              if (response) {
                if (response.status) {
                  if (response.status === 400) {
                    this.returnMessage = response.message;
                    this.classMessage = "error";
                    this.playbadSound();
                    setTimeout(() => {
                      this.returnMessage = "";
                      this.showScanner();
                    }, 1000);
                  } else {
                    this.returnMessage = "erreur";
                    this.classMessage = "error";
                    this.playbadSound();
                    setTimeout(() => {
                      this.returnMessage = "";
                      this.showScanner();
                    }, 1000);
                  }
                } else if (response.code) {
                  this.returnMessage = response.message;
                  if (response.code == 3) {
                    this.classMessage = "error";
                    this.playbadSound();
                  } else if (response.code == 2) {
                    this.classMessage = "warning";
                    this.playSound();
                  } else {
                    this.classMessage = "success";
                    this.playSound();
                  }
                  setTimeout(() => {
                    this.returnMessage = "";
                    this.showScanner();
                  }, 1000);
                } else {
                  this.returnMessage = "erreur";
                  this.classMessage = "error";
                  this.playbadSound();
                  setTimeout(() => {
                    this.returnMessage = "";
                    this.showScanner();
                  }, 1000);
                }
              } else {
                this.returnMessage = "erreur";
                this.classMessage = "error";
                this.playbadSound();
                setTimeout(() => {
                  this.returnMessage = "";
                  this.showScanner();
                }, 1000);
              }
            });
          break;
        case "error":
          this.resultValue =
            "Error Occurred! Check the error message in 'All results'!";
          this.playbadSound();
          break;
        default:
          break;
      }
    },

    resetalert() {
      this.returnMessage = false;
      this.classMessage = false;
    },

    toggleThemode() {
      this.outScanMode = !this.outScanMode;
      if (this.outScanMode) {
        this.classMode = "red_back";
        this.api_url = "https://apidev.urevent.fr/api/Scanapi/scan_dejcfp";
        this.modeMessage = "SORTIE";
      } else {
        this.classMode = "green_back";
        this.api_url = "https://apidev.urevent.fr/api/Scanapi/scan_dejcfp";
        this.modeMessage = "ENTREE";
      }
    },

    showScanner() {
      this.bShowScanner = true;
      this.bShowHome = false;
    },

    hideScanner() {
      this.bShowHome = true;
      this.bShowScanner = false;
    },

    updateTotal() {
      fetch("https://apidev.urevent.fr/api/Scanapi/allincountnow_dej/24")
        .then((response) => response.json())
        .then((json) => {
          this.total = json;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
